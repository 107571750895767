<template>
  <div>
    <TotemEmprestimo v-if="current == 1" @updateCurrent="updateCurrent" />

    <TotemEmprestimoCpf v-if="current == 2" @updateCurrent="updateCurrent" @updateTomador="updateTomador" />

    <TotemEmprestimoSegurado v-if="current == 3" :key="current" @updateCurrent="updateCurrent" :dadosTomador="dadosTomador" />

    <TotemEmprestimoValorDesejado
      v-if="current == 4"
      @updateCurrent="updateCurrent"
      :dadosTomador="dadosTomador"
      @updateValores="updateValores"
      @tipoCalculo="tipoCalculo"
    />

    <TotemEmprestimoValorMensal
      v-if="current == 5"
      @updateCurrent="updateCurrent"
      :dadosTomador="dadosTomador"
      @updateValores="updateValores"
      @tipoCalculo="tipoCalculo"
    />

    <TotemEmprestimoValorTotal
      v-if="current == 6"
      :quantidadeParcelas="quantidadeParcelas"
      :valorDesejado="valorDesejado"
      @updateCurrent="updateCurrent"
      :tipoEmprestimo="tipoEmprestimo"
      :dadosTomador="dadosTomador"
    />
  </div>
</template>
<script>
  import TotemEmprestimo from './TotemEmprestimo.vue'
  import TotemEmprestimoCpf from './TotemEmprestimoCpf.vue'
  import TotemEmprestimoSegurado from './TotemEmprestimoSegurado.vue'
  import TotemEmprestimoValorDesejado from './TotemEmprestimoValorDesejado.vue'
  import TotemEmprestimoValorMensal from './TotemEmprestimoValorMensal.vue'
  import TotemEmprestimoValorTotal from './TotemEmprestimoValorTotal.vue'
  export default {
    components: {
      TotemEmprestimo,
      TotemEmprestimoCpf,
      TotemEmprestimoSegurado,
      TotemEmprestimoValorDesejado,
      TotemEmprestimoValorMensal,
      TotemEmprestimoValorTotal,
    },
    data() {
      return {
        current: 1,
        dadosTomador: null,
        quantidadeParcelas: 0,
        valorDesejado: 0,
        tipoEmprestimo: 1,
      }
    },
    methods: {
      updateCurrent(value) {
        this.current = value
      },
      updateTomador(value) {
        this.dadosTomador = value
      },
      updateValores(value) {
        this.quantidadeParcelas = value.quantidadeParcelas
        this.valorDesejado = value.valorDesejado
      },
      tipoCalculo(value) {
        this.tipoEmprestimo = value
      },
    },
  }
</script>
<style></style>
