<template>
  <div class="tw-flex tw-w-full tw-flex-col tw-justify-center tw-items-center tw-bg-white tw-min-h-screen textoPadrao">
    <div
      class="tw-bg-[#F8F8F8] tw-px-16 tw-py-16 lg:tw-p-12 tw-rounded-3xl tw-flex tw-flex-col tw-items-center md:tw-w-[85%] lg:tw-w-[600px] tw-min-h-[1000px] lg:tw-min-h-[700px]"
    >
      <div class="tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4">
        <div class="tw-w-full tw-flex tw-justify-between">
          <button
            id="tw-p-[0.3rem] tw-rounded-full tw-self-start tw-text-white tw-cursor-pointer"
            title="Voltar para o inicio"
            @click="voltar()"
          >
            <feather-icon icon="ArrowLeftIcon" size="24" />
          </button>
          <button
            id="tw-p-[0.5rem]  tw-rounded-full tw-self-start  tw-text-white tw-cursor-pointer"
            :style="{ backgroundColor: 'rgb(248 113 113)', color: 'white', borderRadius: '100%', padding: '5px' }"
            title="Voltar para o inicio"
            @click="fechar()"
          >
            <feather-icon icon="XIcon" size="24" />
          </button>
        </div>
        <header class="tw-flex tw-flex-col tw-items-center tw-mt-28 lg:tw-mt-0">
          <h1 class="tw-font-extrabold textoPadrao tw-text-[3.5rem] lg:tw-text-[2.5rem] tw-text-center tw-mt-16 lg:tw-mt-10">
            Digite o <span :style="{ color: institutoParams.corTotem }"> valor </span> desejado
          </h1>
        </header>

        <div class="tw-w-full tw-flex tw-flex-col tw-gap-4 tw-px-12 mt-2">
          <div class="tw-text-center mb-2">
            <div
              :style="{
                border: `solid 5px ${institutoParams.corTotem}`,
                'border-radius': '10px',
                padding: '10px',
              }"
              class="mb-2 tw-flex tw-w-full textoPadrao tw-p-2 tw-outline-none tw-text-center tw-text-2xl lg:tw-text-xl"
            >
              <span
                class="tw-absolute tw-ml-6 tw-text-3xl lg:tw-text-xl tw-font-bold"
                :style="{ color: institutoParams.corTotem }"
              >
                R$
              </span>
              <input
                id="inputValorDesejado"
                :class="{
                  'tw-outline-none': true,
                  'tw-w-full': true,
                  'tw-text-3xl': true,
                  'lg:tw-text-xl': true,
                  'tw-text-center': true,
                  'tw-font-bold': true,
                  'placeholder:textoPadrao': true,
                  textoPadrao: true,
                }"
                :style="{
                  'font-size': '1.55rem',
                }"
                placeholder="Digite o valor desejado"
                v-model="valorDesejado"
                inputmode="numeric"
                v-number="number"
                @input="validarValorMaximo"
              />
            </div>
          </div>

          <div>
            <h1 class="tw-font-extrabold textoPadrao tw-text-[2rem] lg:tw-text-[2rem] tw-text-center">
              Em quantas <span :style="{ color: institutoParams.corTotem }">vezes</span>?
            </h1>
          </div>

          <div class="tw-text-center">
            <input
              id="inputParcelas"
              ref="inputParcelas"
              type="number"
              :class="{
                'tw-outline-none': true,
                'tw-w-full': true,
                'tw-text-2xl': true,
                'lg:tw-text-xl': true,
                'tw-text-center': true,
                'tw-font-bold': true,
                textoPadrao: true,
              }"
              :style="{
                color: institutoParams.corTotem,
                padding: '10px',
                border: `solid 5px ${institutoParams.corTotem}`,
                'font-size': '1.55rem',
                'font-wheight': 'bold',
                'border-radius': '10px',
              }"
              placeholder="Insira a quantidade de parcelas"
              v-model.number="quantidadeParcelas"
              :min="1"
              :max="institutoParams.limiteParcelas"
              @input="validarLimiteParcela"
            />

            <div class="tw-flex tw-justify-center" :class="errorParcelas && 'tw-text-red-500'">
              <p class="tw-text-2xl lg:tw-text-xl tw-mt-2 tw-mr-2 tw-font-semibold" v-if="errorParcelas">Atenção:</p>
              <p class="tw-text-2xl lg:tw-text-xl tw-mt-2">
                Quantidade
                <span
                  :style="{ color: errorParcelas ? '#EF4444' : institutoParams.corTotem }"
                  :class="errorParcelas && 'tw-text-red-500'"
                  >máxima</span
                >: {{ institutoParams.limiteParcelas }} Meses
              </p>
            </div>
          </div>
        </div>

        <button
          id="botaoSeguir"
          @click="finalizar()"
          :disabled="!quantidadeParcelas || !valorDesejado || errorParcelas || errorValor"
          :style="{
            background:
              !quantidadeParcelas || !valorDesejado || errorParcelas || errorValor ? '#c7ccd2' : institutoParams.corTotem,
          }"
          :class="`mt-1 tw-text-white tw-mt-24 lg:tw-mt-10 tw-p-10 tw-rounded-full tw-font-bold tw-text-2xl lg:tw-text-lg`"
        >
          <feather-icon icon="ArrowRightIcon" size="32" />
        </button>
      </div>
    </div>
    <footer class="tw-w-full tw-text-center tw-mt-10">
      <span class="brand-logo">
        <b-img
          :src="institutoParams.logo != null ? institutoParams.logo : require(`@/assets/images/logo/Novos/logoCliente.png`)"
          alt="logo"
          variant="light-primary"
          badge
          badge-variant="success"
          class="tw-h-40 lg:tw-h-24 badge-minimal"
          ref="refPreviewEl"
          rounded
        />
      </span>
    </footer>
  </div>
</template>

<script>
  import { formatarValorReais } from '@/utils/Functions/Formatacao/Formatacao'
  import { formatarParaNumber } from '@/libs/utils'
  import Cleave from 'vue-cleave-component'
  export default {
    components: {
      Cleave,
    },
    props: {
      dadosTomador: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        institutoParams: {
          corTotem: '#0000CC',
        },

        quantidadeParcelas: null,
        valorDesejado: null,
        number: 0,
        errorValor: false,
        errorParcelas: false,
      }
    },
    mounted() {
      this.institutoParams = JSON.parse(localStorage.getItem('institutoParams'))
      this.institutoParams.limiteParcelas = this.dadosTomador.numeroMaximoParcelas
      this.$nextTick(() => {
        this.$refs.inputParcelas.addEventListener('focus', this.scrollToButton)
        this.$refs.inputParcelas.addEventListener('click', this.scrollToButton)
      })
    },
    beforeDestroy() {
      this.$refs.inputParcelas.removeEventListener('focus', this.scrollToButton)
      this.$refs.inputParcelas.removeEventListener('click', this.scrollToButton)
    },
    methods: {
      formatarValorReais,
      voltar() {
        this.$emit('updateCurrent', 3)
      },
      fechar() {
        this.dadosTomador = null
        this.$emit('updateCurrent', 1)
      },
      validarValorMaximo() {
        const valor = formatarParaNumber(this.valorDesejado)
        const valorMaximo = this.institutoParams.valorMaximoEmprestimo
        if (valor > valorMaximo) {
          this.errorValor = true
        } else {
          this.errorValor = false
        }
      },
      validarLimiteParcela(event) {
        const valor = parseInt(event.target.value, 10)
        if (valor > this.institutoParams.limiteParcelas) {
          this.errorParcelas = true
        } else {
          this.errorParcelas = false
        }
      },
      finalizar() {
        if (this.errorValor || this.errorParcelas) {
          return
        }
        this.$emit('updateCurrent', 6)
        this.$emit('tipoCalculo', 2)
        this.$emit('updateValores', {
          valorDesejado: formatarParaNumber(this.valorDesejado),
          quantidadeParcelas: this.quantidadeParcelas,
        })
      },
      scrollToButton() {
        this.$nextTick(() => {
          const button = document.getElementById('botaoSeguir')
          button.scrollIntoView({ behavior: 'smooth', block: 'center' })
        })
      },
    },
  }
</script>

<style scoped>
  #inputValorDesejado::placeholder,
  #inputParcelas::placeholder {
    color: rgb(66, 66, 66) !important;
    font-weight: normal !important;
  }

  .textoPadrao {
    color: rgb(66, 66, 66) !important;
  }
</style>
