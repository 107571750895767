<template>
  <div class="tw-flex tw-w-full tw-flex-col tw-justify-center tw-items-center tw-bg-white tw-min-h-screen textoPadrao">
    <div
      class="tw-bg-[#F8F8F8] tw-px-16 tw-py-16 lg:tw-p-12 tw-rounded-3xl tw-flex tw-flex-col tw-items-center md:tw-w-[85%] lg:tw-w-[600px] tw-min-h-[1000px] lg:tw-min-h-[700px]"
    >
      <div class="tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-10">
        <div class="tw-w-full tw-flex tw-justify-between">
          <button
            id="tw-p-[0.3rem] tw-rounded-full tw-self-start tw-text-white tw-cursor-pointer"
            title="Voltar para o inicio"
            @click="voltar()"
          >
            <feather-icon icon="ArrowLeftIcon" size="24" />
          </button>

          <button
            id="tw-p-[0.5rem]  tw-rounded-full tw-self-start  tw-text-white tw-cursor-pointer"
            :style="{ backgroundColor: 'rgb(248 113 113)', color: 'white', borderRadius: '100%', padding: '5px' }"
            title="Voltar para o inicio"
            @click="fechar()"
          >
            <feather-icon icon="XIcon" size="24" />
          </button>
        </div>

        <header class="text-center tw-mt-10 lg:tw-mt-0">
          <h1 class="tw-font-bold tw-text-6xl lg:tw-text-4xl textoPadrao">
            Olá <span :style="{ color: institutoParams.corTotem }"> {{ primeiroNome(dadosTomador.nomeCompleto) }}! </span> 👋🏼
          </h1>

          <h2 class="tw-font-bold tw-text-5xl mt-2 lg:tw-text-3xl textoPadrao">
            <span :style="{ color: institutoParams.corTotem }">Como</span> você deseja simular?
          </h2>
        </header>

        <div class="tw-w-full tw-flex tw-justify-center tw-flex-col tw-items-center cursor-pointer tw-mt-2">
          <div
            class="tw-border-2 tw-py-10 tw-mt-2 tw-rounded-lg tw-px-6 cursor-pointer"
            :style="{
              backgroundColor: institutoParams.corTotem,
              color: 'white',
              border: '',
            }"
            @click="selecionarOpcao(1)"
          >
            <div class="tw-w-full tw-flex tw-items-center tw-gap-4">
              <div>
                <p class="tw-text-4xl lg:tw-text-3xl tw-font-bold">Valor Desejado</p>
                <p class="tw-text-2xl lg:tw-text-xl">
                  Informe o valor do empréstimo que você deseja e a quantidade de parcelas.
                </p>
              </div>
            </div>
          </div>

          <div
            class="tw-border-2 tw-py-10 tw-mt-2 tw-rounded-lg tw-px-6 cursor-pointer"
            :style="{
              backgroundColor: institutoParams.corTotem,
              color: 'white',
              border: '',
            }"
            @click="selecionarOpcao(2)"
          >
            <div class="tw-w-full tw-flex tw-items-center tw-gap-4">
              <div>
                <p class="tw-text-4xl lg:tw-text-3xl tw-font-bold">Valor da Parcela</p>
                <p class="tw-text-2xl lg:tw-text-xl">Informar quanto você consegue pagar por mês e a quantidade de parcelas.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <footer class="tw-w-full tw-text-center tw-mt-10">
      <span class="brand-logo">
        <b-img
          :src="institutoParams.logo != null ? institutoParams.logo : require(`@/assets/images/logo/Novos/logoCliente.png`)"
          alt="logo"
          variant="light-primary"
          badge
          badge-variant="success"
          class="tw-h-40 lg:tw-h-24 badge-minimal"
          ref="refPreviewEl"
          rounded
        />
      </span>
    </footer>
  </div>
</template>
<script>
  import { formatarParaNumber, primeiroNome } from '@/libs/utils'
  export default {
    components: {
      formatarParaNumber,
    },
    props: {
      dadosTomador: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        siglaDoInstituto: {
          corTotem: '#0000CC',
        },
        institutoParams: {
          logo: null,
        },
        opcaoSelecionada: 1,
      }
    },
    mounted() {
      this.institutoParams = JSON.parse(localStorage.getItem('institutoParams'))
    },
    methods: {
      voltar() {
        this.$emit('updateCurrent', 2)
      },
      fechar() {
        this.dadosTomador = null
        this.$emit('updateCurrent', 1)
      },
      primeiroNome,
      selecionarOpcao(opcao) {
        this.opcaoSelecionada = opcao
        this.enviar()
      },
      enviar() {
        if (this.opcaoSelecionada == 1) {
          this.$emit('updateCurrent', 4)
        }
        if (this.opcaoSelecionada == 2) {
          this.$emit('updateCurrent', 5)
        }
      },
    },
  }
</script>

<style scoped>
  .textoPadrao {
    color: rgb(66, 66, 66) !important;
  }
</style>
