var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-flex tw-w-full tw-flex-col tw-justify-center tw-items-center tw-bg-white tw-min-h-screen textoPadrao"},[_c('div',{staticClass:"tw-bg-[#F8F8F8] tw-px-16 tw-py-16 lg:tw-p-12 tw-rounded-3xl tw-flex tw-flex-col tw-items-center md:tw-w-[85%] lg:tw-w-[600px] tw-min-h-[1000px] lg:tw-min-h-[700px]"},[_c('div',{staticClass:"tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-10"},[_c('div',{staticClass:"tw-w-full tw-flex tw-justify-between"},[_c('button',{attrs:{"id":"tw-p-[0.3rem] tw-rounded-full tw-self-start tw-text-white tw-cursor-pointer","title":"Voltar para o inicio"},on:{"click":function($event){return _vm.voltar()}}},[_c('feather-icon',{attrs:{"icon":"ArrowLeftIcon","size":"24"}})],1),_c('button',{style:({ backgroundColor: 'rgb(248 113 113)', color: 'white', borderRadius: '100%', padding: '5px' }),attrs:{"id":"tw-p-[0.5rem]  tw-rounded-full tw-self-start  tw-text-white tw-cursor-pointer","title":"Voltar para o inicio"},on:{"click":function($event){return _vm.fechar()}}},[_c('feather-icon',{attrs:{"icon":"XIcon","size":"24"}})],1)]),_c('header',{staticClass:"tw-flex tw-flex-col tw-items-center tw-mt-28 lg:tw-mt-0"},[_c('h1',{staticClass:"tw-font-extrabold textoPadrao tw-text-[3.5rem] lg:tw-text-[2.5rem] tw-text-center tw-mt-16 lg:tw-mt-10"},[_vm._v(" Digite o "),_c('span',{style:({ color: _vm.institutoParams.corTotem })},[_vm._v(" valor ")]),_vm._v(" desejado ")])]),_c('div',{staticClass:"tw-w-full tw-flex tw-flex-col tw-gap-4 tw-px-12 mt-2"},[_c('div',{staticClass:"tw-text-center"},[_c('div',{staticClass:"tw-flex tw-w-full textoPadrao tw-p-2 tw-outline-none tw-text-center tw-text-2xl lg:tw-text-xl",style:({
              border: ("solid 5px " + (_vm.institutoParams.corTotem)),
              'border-radius': '10px',
              padding: '10px',
            })},[_c('span',{staticClass:"tw-absolute tw-ml-6 lg:tw-ml-3 mr-1 tw-font-bold",style:({ color: _vm.institutoParams.corTotem })},[_vm._v(" R$ ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.valorDesejado),expression:"valorDesejado"},{name:"number",rawName:"v-number",value:(_vm.number),expression:"number"}],class:{
                'tw-outline-none': true,
                'tw-w-full': true,
                'tw-text-2xl': true,
                'lg:tw-text-xl': true,
                'tw-text-center': true,
                'tw-font-bold': true,
                textoPadrao: true,
              },style:({
                color: _vm.institutoParams.corTotem,
                'font-size': '1.55rem',
              }),attrs:{"id":"inputValorDesejado","placeholder":"Informe o valor","inputmode":"numeric"},domProps:{"value":(_vm.valorDesejado)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.valorDesejado=$event.target.value},_vm.validarValorMaximo]}})]),_c('div',{staticClass:"tw-flex tw-justify-center mb-2",class:_vm.errorValor && 'tw-text-red-500'},[(_vm.errorValor)?_c('p',{staticClass:"tw-text-2xl lg:tw-text-xl tw-mt-2 tw-mr-2 tw-font-semibold"},[_vm._v("Atenção:")]):_vm._e(),_c('p',{staticClass:"tw-text-2xl lg:tw-text-xl tw-mt-2"},[_vm._v(" Valor "),_c('span',{class:_vm.errorValor && 'tw-text-red-500',style:({ color: _vm.errorValor ? '#EF4444' : _vm.institutoParams.corTotem })},[_vm._v("máximo")]),_vm._v(" "+_vm._s(_vm.formatarValorReais(_vm.institutoParams.valorMaximoEmprestimo))+" ")])])]),_c('div',[_c('h1',{staticClass:"tw-font-extrabold textoPadrao tw-text-[2rem] lg:tw-text-[2rem] tw-text-center"},[_vm._v(" Em quantas "),_c('span',{style:({ color: _vm.institutoParams.corTotem })},[_vm._v("vezes")]),_vm._v("? ")])]),_c('div',{staticClass:"tw-text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.quantidadeParcelas),expression:"quantidadeParcelas",modifiers:{"number":true}}],ref:"inputParcelas",class:{
              'tw-outline-none': true,
              'tw-w-full': true,
              'tw-text-2xl': true,
              'lg:tw-text-xl': true,
              'tw-text-center': true,
              'tw-font-bold': true,
              textoPadrao: true,
            },style:({
              color: _vm.institutoParams.corTotem,
              padding: '10px !important',
              'font-size': '1.55rem',
              'font-wheight': 'bold',
              border: ("solid 5px " + (_vm.institutoParams.corTotem)),
              'border-radius': '10px',
              padding: '10px',
            }),attrs:{"id":"inputParcelas","type":"number","placeholder":"Insira a quantidade de parcelas","min":1,"max":_vm.institutoParams.limiteParcelas},domProps:{"value":(_vm.quantidadeParcelas)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.quantidadeParcelas=_vm._n($event.target.value)},_vm.validarLimiteParcela],"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"tw-flex tw-justify-center",class:_vm.errorParcelas && 'tw-text-red-500'},[(_vm.errorParcelas)?_c('p',{staticClass:"tw-text-2xl lg:tw-text-xl tw-mt-2 tw-mr-2 tw-font-semibold"},[_vm._v("Atenção:")]):_vm._e(),_c('p',{staticClass:"tw-text-2xl lg:tw-text-xl tw-mt-2"},[_vm._v(" Quantidade "),_c('span',{class:_vm.errorParcelas && 'tw-text-red-500',style:({ color: _vm.errorParcelas ? '#EF4444' : _vm.institutoParams.corTotem })},[_vm._v("máxima")]),_vm._v(": "+_vm._s(_vm.institutoParams.limiteParcelas)+" Meses ")])])])]),_c('button',{class:"mt-1 tw-text-white tw-mt-24 lg:tw-mt-10 tw-p-10 tw-rounded-full tw-font-bold tw-text-2xl lg:tw-text-lg",style:({
          background:
            !_vm.quantidadeParcelas || !_vm.valorDesejado || _vm.errorParcelas || _vm.errorValor ? '#c7ccd2' : _vm.institutoParams.corTotem,
        }),attrs:{"disabled":!_vm.quantidadeParcelas || !_vm.valorDesejado || _vm.errorParcelas || _vm.errorValor,"id":"botaoSeguir"},on:{"click":function($event){return _vm.finalizar()}}},[_c('feather-icon',{attrs:{"icon":"ArrowRightIcon","size":"32"}})],1)])]),_c('footer',{staticClass:"tw-w-full tw-text-center tw-mt-10"},[_c('span',{staticClass:"brand-logo"},[_c('b-img',{ref:"refPreviewEl",staticClass:"tw-h-40 lg:tw-h-24 badge-minimal",attrs:{"src":_vm.institutoParams.logo != null ? _vm.institutoParams.logo : require("@/assets/images/logo/Novos/logoCliente.png"),"alt":"logo","variant":"light-primary","badge":"","badge-variant":"success","rounded":""}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }