var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-flex tw-w-full tw-flex-col tw-justify-center tw-items-center tw-bg-white tw-min-h-screen textoPadrao"},[_c('div',{staticClass:"tw-bg-[#F8F8F8] tw-px-16 tw-py-16 lg:tw-p-12 tw-rounded-3xl tw-flex tw-flex-col tw-items-center md:tw-w-[85%] lg:tw-w-[600px] tw-min-h-[1000px] lg:tw-min-h-[700px]"},[_c('div',{staticClass:"tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-10"},[_c('div',{staticClass:"tw-w-full tw-flex tw-justify-between"},[_c('button',{attrs:{"id":"tw-p-[0.3rem] tw-rounded-full tw-self-start tw-text-white tw-cursor-pointer","title":"Voltar para o inicio"},on:{"click":function($event){return _vm.voltar()}}},[_c('feather-icon',{attrs:{"icon":"ArrowLeftIcon","size":"24"}})],1),_c('button',{style:({ backgroundColor: 'rgb(248 113 113)', color: 'white', borderRadius: '100%', padding: '5px' }),attrs:{"id":"tw-p-[0.5rem]  tw-rounded-full tw-self-start  tw-text-white tw-cursor-pointer","title":"Voltar para o inicio"},on:{"click":function($event){return _vm.fechar()}}},[_c('feather-icon',{attrs:{"icon":"XIcon","size":"24"}})],1)]),_c('header',{staticClass:"text-center tw-mt-10 lg:tw-mt-0"},[_c('h1',{staticClass:"tw-font-bold tw-text-6xl lg:tw-text-4xl textoPadrao"},[_vm._v(" Olá "),_c('span',{style:({ color: _vm.institutoParams.corTotem })},[_vm._v(" "+_vm._s(_vm.primeiroNome(_vm.dadosTomador.nomeCompleto))+"! ")]),_vm._v(" 👋🏼 ")]),_c('h2',{staticClass:"tw-font-bold tw-text-5xl mt-2 lg:tw-text-3xl textoPadrao"},[_c('span',{style:({ color: _vm.institutoParams.corTotem })},[_vm._v("Como")]),_vm._v(" você deseja simular? ")])]),_c('div',{staticClass:"tw-w-full tw-flex tw-justify-center tw-flex-col tw-items-center cursor-pointer tw-mt-2"},[_c('div',{staticClass:"tw-border-2 tw-py-10 tw-mt-2 tw-rounded-lg tw-px-6 cursor-pointer",style:({
            backgroundColor: _vm.institutoParams.corTotem,
            color: 'white',
            border: '',
          }),on:{"click":function($event){return _vm.selecionarOpcao(1)}}},[_vm._m(0)]),_c('div',{staticClass:"tw-border-2 tw-py-10 tw-mt-2 tw-rounded-lg tw-px-6 cursor-pointer",style:({
            backgroundColor: _vm.institutoParams.corTotem,
            color: 'white',
            border: '',
          }),on:{"click":function($event){return _vm.selecionarOpcao(2)}}},[_vm._m(1)])])])]),_c('footer',{staticClass:"tw-w-full tw-text-center tw-mt-10"},[_c('span',{staticClass:"brand-logo"},[_c('b-img',{ref:"refPreviewEl",staticClass:"tw-h-40 lg:tw-h-24 badge-minimal",attrs:{"src":_vm.institutoParams.logo != null ? _vm.institutoParams.logo : require("@/assets/images/logo/Novos/logoCliente.png"),"alt":"logo","variant":"light-primary","badge":"","badge-variant":"success","rounded":""}})],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-w-full tw-flex tw-items-center tw-gap-4"},[_c('div',[_c('p',{staticClass:"tw-text-4xl lg:tw-text-3xl tw-font-bold"},[_vm._v("Valor Desejado")]),_c('p',{staticClass:"tw-text-2xl lg:tw-text-xl"},[_vm._v(" Informe o valor do empréstimo que você deseja e a quantidade de parcelas. ")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-w-full tw-flex tw-items-center tw-gap-4"},[_c('div',[_c('p',{staticClass:"tw-text-4xl lg:tw-text-3xl tw-font-bold"},[_vm._v("Valor da Parcela")]),_c('p',{staticClass:"tw-text-2xl lg:tw-text-xl"},[_vm._v("Informar quanto você consegue pagar por mês e a quantidade de parcelas.")])])])}]

export { render, staticRenderFns }